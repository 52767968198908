import { useEffect, useRef, useState } from 'react'

type HookInput = string

export default function useRequiredBecameEmpty(value: HookInput) {
  const currentLength = useRef(value.length)
  const [becameEmpty, setBecameEmpty] = useState(false)

  useEffect(() => {
    setBecameEmpty(currentLength.current > 0 && value.length === 0)
    currentLength.current = value.length
  }, [value])

  return becameEmpty
}
