import { navigate } from 'gatsby'
import React, { FormEvent, ReactElement, useEffect, useState } from 'react'
import AppStateBackup from '../../../lib/app-state-backup'
import { useAppDispatch, useAppState } from '../../../state/app-context'
import { AppStateDispatchType } from '../../../types/enums'
import { Button } from '../../elements'
import ChannelSelect from './channel-select'
// import LanguageSelect from './language-select'
import RegionSelect from './region-select'
import StoreSelect from './store-select'

function LanguageSelectorForm(): ReactElement {
  const { channel, appLanguage, store, region, ...rest } = useAppState()
  const dispatch = useAppDispatch()
  const [errors, setErrors] = useState({ channel: false, store: false, region: false })
  const [enableErrorTracking, setEnableErrorTracking] = useState(false)

  useEffect(() => {
    setErrors({
      channel: Boolean(!channel),
      region: Boolean(!region),
      store: Boolean(!store),
    })
  }, [channel, region, store])

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setEnableErrorTracking(true)

    if (Object.values(errors).includes(true)) {
      return false
    }

    dispatch({
      type: AppStateDispatchType.updateFTU,
      payload: false,
    })

    await AppStateBackup.getInstance().storeBackup({
      ...rest,
      appLanguage,
      channel,
      region,
      store,
      firstTimeUser: false,
    })

    return navigate(`/${appLanguage}/`)
  }

  return (
    <form onSubmit={onSubmit}>
      {/* <LanguageSelect /> */}
      <ChannelSelect containsError={enableErrorTracking && errors.channel} />
      <RegionSelect containsError={enableErrorTracking && errors.region} />
      <StoreSelect containsError={enableErrorTracking && errors.store} />

      <div className="flex justify-center">
        <Button type="submit" variant="secondary">
          Save & Continue
        </Button>
      </div>
    </form>
  )
}

export default LanguageSelectorForm
