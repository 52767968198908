import React, { ChangeEvent, ReactElement, useEffect, useMemo } from 'react'
import useRequiredBecameEmpty from '../../../hooks/use-required-became-empty'
import { useAppState, useAppDispatch } from '../../../state/app-context'
import { ADOptions, GTROptions, NZDOptions, INDOptions } from '../../../types/constants'
import { Elements } from '../../../types/elements'
import { AppStateDispatchType } from '../../../types/enums'
import { Keys } from '../../../types/generic'
import getOptionArrayValues from '../../../utils/get-option-array-values'
import { InputField, Select } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'

export default function RegionSelect({ containsError = false }: Elements.SettingsFormElement): ReactElement {
  const { region, channel } = useAppState()
  const dispatch = useAppDispatch()
  const becameEmpty = useRequiredBecameEmpty(region)
  const selectOptions = useMemo(() => {
    switch (channel) {
      case 'autraliaDomestic':
        return ADOptions
      case 'globalTravelRetail':
        return GTROptions
      case 'newZealandDomestic':
        return NZDOptions
	case 'IndiaDomestic':
		return INDOptions
      case '':
      default:
        return []
    }
  }, [channel])

  useEffect(() => {
    const resetRegionValue = () => {
      dispatch({
        type: AppStateDispatchType.updateRegion,
        payload: '',
      })
    }

    const optionKeys = getOptionArrayValues(selectOptions)

    if (channel === '' || !optionKeys.includes(region)) {
      resetRegionValue()
    }
  }, [channel, dispatch, selectOptions, region])

  const onChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target

    dispatch({
      type: AppStateDispatchType.updateRegion,
      payload: value as Keys.GTRRegion,
    })
  }

  return (
    <InputField error={containsError || becameEmpty}>
      <Select
        label={{
          htmlFor: 'region',
          children: 'region',
          isRequired: true,
        }}
        select={{
          value: region,
          onChange,
          options: [{ value: '', content: 'Select' }, ...selectOptions],
          name: 'region',
          id: 'region',
        }}
      />
      {(containsError || becameEmpty) && <InputErrorMessage>*This field is mandatory</InputErrorMessage>}
    </InputField>
  )
}
