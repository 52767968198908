import React, { ReactElement } from 'react'
import LanguageSelectorForm from '../components/language-switcher-form'
import { PageTitle } from '../grouping'

export const LANGUAGE_LOCATION_BASE_CLASS = 'c__language-location'

export default function LanguageLocation(): ReactElement {
  return (
    <div className={`box u__mc--vertical ${LANGUAGE_LOCATION_BASE_CLASS}`}>
      <PageTitle
        subTitle="Settings"
        title={
          <>
            Language
            <br />& Location
          </>
        }
        alignment="center"
      />

      <LanguageSelectorForm />
    </div>
  )
}
