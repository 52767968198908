import React, { ChangeEvent, ReactElement, useEffect, useMemo } from 'react'
import useRequiredBecameEmpty from '../../../hooks/use-required-became-empty'
import { useAppState, useAppDispatch } from '../../../state/app-context'
import { storeMapping } from '../../../types/constants'
import { Elements } from '../../../types/elements'
import { AppStateDispatchType, StoreNames } from '../../../types/enums'
import { Keys } from '../../../types/generic'
import getOptionArrayValues from '../../../utils/get-option-array-values'
import { InputField, Select } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'

export default function StoreSelect({ containsError = false }: Elements.SettingsFormElement): ReactElement {
  const { store, region } = useAppState()
  const dispatch = useAppDispatch()
  const becameEmpty = useRequiredBecameEmpty(store || '')
  const storeOptions = useMemo(() => {
    if (region === '') {
      return []
    }

    return storeMapping[region].map((key) => ({
      value: key,
      content: StoreNames[key],
    }))
  }, [region])

  useEffect(() => {
    function resetStoreValue() {
      dispatch({
        type: AppStateDispatchType.updateStore,
        payload: undefined,
      })
    }

    const optionKeys = getOptionArrayValues(storeOptions)

    if (region === '' || !optionKeys.includes(store)) {
      resetStoreValue()
    }
  }, [region, dispatch, storeOptions, store])

  const onChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target

    dispatch({
      type: AppStateDispatchType.updateStore,
      payload: value as Keys.StoreNames,
    })
  }

  return (
    <InputField error={containsError || becameEmpty}>
      <Select
        label={{
          htmlFor: 'store',
          children: 'store',
          isRequired: true,
        }}
        select={{
          value: store,
          onChange,
          options: [{ value: '', content: 'Select' }, ...storeOptions],
          name: 'store',
          id: 'store',
        }}
      />
      {(containsError || becameEmpty) && <InputErrorMessage>*This field is mandatory</InputErrorMessage>}
    </InputField>
  )
}
