import React, { ReactElement } from 'react'
import PageLayout from '../components/components/page-layout'
import { AnchorButton, Section } from '../components/elements'
import LanguageLocation, { LANGUAGE_LOCATION_BASE_CLASS } from '../components/screens/language-location'
import { Page } from '../types/page'

export default function SetupTemplate({ pageContext: { language } }: Page.TemplateSetupProperties): ReactElement {
  return (
    <PageLayout lang={language}>
      <Section>
        <div className="container">
          <div className="row justify-center">
            <div className={`col ${LANGUAGE_LOCATION_BASE_CLASS}--outer-column`}>
              <LanguageLocation />

              <div className="flex justify-center">
                <AnchorButton variant="default" href={`/${language}/onboarding`}>
                  On Boarding
                </AnchorButton>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </PageLayout>
  )
}
