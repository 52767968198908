import { AustraliaDomesticRegions, Channels, GTRRegions, Languages, newZealandDomesticRegions, IndiaDomesticRegions } from './enums'
import { Keys } from './generic'

const storeMapping: Record<Keys.Region, Keys.StoreNames[]> = {
  uk: ['lhr_t2_destinationbar_slot1', 'lhr_t5_whiskey_bar'],
  indiaSubcontinentMiddleEast: ['hyderabad_airport', 'flemingo_location', 'beirut', 'bahrain'],
  southEastAsia: ['singapore_changi'],
  northAsia: [
    'taipei',
    'hong_kong',
    'cdfg_haikuo_sis',
    'cdfg_haitang_sis',
    'shanghai_a1',
    'hainan_htb_3f',
    'htdf_sis_b1',
    'sanya_french_garden',
    'taipei_3028_sis',
  ],
  australiaNewZealand: ['sydney_airport_t1', 'auckland_lagardere', 'auckland_aer_rianta', 'melbourne_departure'],
  act: ['market_cellars_fyshwick', 'jim_murphy_cellars_canberra'],
  nsw: [
    'billabong_cellars_griffith',
    'east_toongabbie_liquor',
    'lugarno_cellars',
    'charlies_liquor_barn_panania',
    'quakers_hill_cellars',
    'warners_at_the_bay_hotel',
    'clemton_park_cellars',
    'institchu',
    'boozebud',
    'iga_ritchies',
    'bel_brio_nsw',
    'harrys_liquor_minchinbury',
    'head_office',
    'shortys_liquor_pyrmont',
  ],
  vic: [
    'cambridge_cellars_caulfield',
    'lamanna_direct_essendon',
    'gladstone_park_hotel_tullamarine',
    'bottle_o_sanctuary_lakes',
    'mulgrave_cellars',
    'mccoppins_liquor_fitzroy',
    'ms_collins',
    'candela_nuovo',
    'iga_carrum_downs',
    'crown_cellar_co',
  ],
  sa: ['parafield_liquor_store_pals', 'arkaba_hotel_fullarton', 'parade_cellars_norwood', 'pals_liquor_sa'],
  qld: ['bohle_barn_townsville', 'ashmore_tavern', 'runcorn_tavern_sunnybank_hills'],
  wa: ['liquor_shed_jandakot', 'copper_and_oak_liquor_tuart_hill', 'crown_perth', 'liquor_shed_wa'],
  tas: ['bottleshop_gasworks', 'bottleshop_st_ives'],
  newZealand: ['auckland'],
  ludhiana: ['na'],
  delhi: ['na'],
  gurgaon: ['na'],
  jaipur: ['na'],
  chennai: ['na'],
  mumbai: ['na'],
  pune: ['na'],
  goa: ['na'],
  bangalore: ['na'],
  hyderabad: ['na'],
  kolkata: ['na'],
  chandigarh: ['na'],
  indore  : ['na'],
}

const channelOptions = (Object.keys(Channels) as Keys.Channel[]).map((v) => {
  return {
    value: v,
    content: Channels[v],
  }
})

const languageOptions = (Object.keys(Languages) as Keys.Language[]).map((v) => {
  return {
    value: v,
    content: Languages[v],
  }
})

const GTROptions = (Object.keys(GTRRegions) as Keys.GTRRegion[]).map((v) => {
  return {
    value: v,
    content: GTRRegions[v],
  }
})

const ADOptions = (Object.keys(AustraliaDomesticRegions) as Keys.ADRegion[]).map((v) => {
  return {
    value: v,
    content: AustraliaDomesticRegions[v],
  }
})

const NZDOptions = (Object.keys(newZealandDomesticRegions) as Keys.NZDRegion[]).map((v) => {
  return {
    value: v,
    content: newZealandDomesticRegions[v],
  }
})

const INDOptions = (Object.keys(IndiaDomesticRegions) as Keys.INDRegion[]).map((v) => {
	return {
	  value: v,
	  content: IndiaDomesticRegions[v],
	}
  })


const VIDEO_PATH = '/videos/GFPL-How-To-Video_720p.mp4'

export { storeMapping, channelOptions, languageOptions, GTROptions, ADOptions, NZDOptions, INDOptions, VIDEO_PATH }
