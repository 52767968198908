import React, { ChangeEvent, ReactElement } from 'react'
import useRequiredBecameEmpty from '../../../hooks/use-required-became-empty'
import { useAppState, useAppDispatch } from '../../../state/app-context'
import { channelOptions } from '../../../types/constants'
import { Elements } from '../../../types/elements'
import { AppStateDispatchType } from '../../../types/enums'
import { Keys } from '../../../types/generic'
import { InputField, Select } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'

export default function ChannelSelect({ containsError = false }: Elements.SettingsFormElement): ReactElement {
  const { channel } = useAppState()
  const dispatch = useAppDispatch()
  const becameEmpty = useRequiredBecameEmpty(channel)

  const onChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target

    dispatch({
      type: AppStateDispatchType.updateChannel,
      payload: value as Keys.Channel,
    })
  }

  return (
    <InputField error={containsError || becameEmpty}>
      <Select
        label={{
          htmlFor: 'channel',
          children: 'Channel',
          isRequired: true,
        }}
        select={{
          value: channel,
          onChange,
          options: [{ value: '', content: 'Select' }, ...channelOptions],
          name: 'channel',
          id: 'channel',
        }}
      />

      {(containsError || becameEmpty) && <InputErrorMessage>*This field is mandatory</InputErrorMessage>}
    </InputField>
  )
}
